export const PGN_UPLOADS_URL = "/pgn_uploads/";
export const TOAST_DURATION = 2000;
export const INFINITE_SCROLL_PAGINATION_RESULTS = 12;
export const TASK_FAILED_TIME_SECONDS = 120;
export const CDN_URL = "https://duhgfw2g1t6v3.cloudfront.net/"; //! change me when you know the DNS resolution
export const CDN_ENABLED = "true";

export const timeConstants = {
  DAY_MILLIS: 86_400_000,
};
